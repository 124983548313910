<template>
  <section>
    <land-hero-alt
      :title="heroAlt.title"
      :cover="heroAlt.cover"
      :assist-color="heroAlt.assistColor"
      :divisible="heroAlt.divisible"
    />
    <land-intro-image-text
      :back-color="intro1.backColor"
      :assist-color="intro1.assistColor"
      :header="intro1.header"
      :content="intro1.content"
    />
    <land-intro-image-text
      :back-color="intro2.backColor"
      :assist-color="intro2.assistColor"
      :header="intro2.header"
      :content="intro2.content"
    />
    <land-intro-image-text
      :back-color="intro3.backColor"
      :assist-color="intro3.assistColor"
      :header="intro3.header"
      :content="intro3.content"
    />
    <land-intro-icons-ver
      :back-color="jiuDian.backColor"
      :assist-color="jiuDian.assistColor"
      :header="jiuDian.header"
      :features="jiuDian.features"
      :button="jiuDian.button"
    />
    <land-intro-image-text
      :back-color="intro4.backColor"
      :assist-color="intro4.assistColor"
      :header="intro4.header"
      :content="intro4.content"
    />
    <section-foot />
  </section>
</template>

<script>
  import data from '@/data/co.data'
  import mixSupportBanner from '@/pages/mixins/support/mix.support.banner'

  export default {
    components: {
      SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    metaInfo: { title: '技术支持' },
    mixins: [
      mixSupportBanner
    ],
    data () {
      return {
        userInfo: {},
        heroAlt: {
          ...data.banner.support[0],
          title: '技术赋能安全，智能护航发展',
          assistColor: 'primary',
          divisible: false,
          items: [],
        },
        intro1: data.scheme.resumes.ZhiZaoYe,
        intro2: data.scheme.resumes.HuaGong,
        intro3: data.scheme.resumes.WeiHuaPin,
        jiuDian: data.scheme.resumes.JiuDian,
        intro4: data.scheme.resumes.NongYe,
      }
    },
    created () {
    },
    methods: {
    }
  }
</script>
